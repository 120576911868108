.card {
  width: 300px;
  max-width: 300px;
  cursor: pointer;
  color: #332E1A;
  border-radius: 10px;
  
}

.card:hover {
  background-color: #332E1A11;
}
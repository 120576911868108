.subscription-info {
    display: flex;
    justify-content: space-between;
    background-color: #d3c89e;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding-left: 10em;
    padding-right: 10em;
    margin-top:10px

}

.subscription-info p {
    margin: 5px 0;
    font-size: 16px;

}

.pago {
    font-weight: bold;
    color: #0c5e17;
}


.aguardando {
    font-weight: bold;
    color: #b38826;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.table th {
    background-color: #d4a73a;
    border: 1px solid #856900;
    padding: 2px;
    text-align: center;
    color: #fff;
}

.table td {
    border: 1px solid #856900;
    padding: 2px;
    text-align: center;
}


.table tbody tr:nth-child(even) {
    background-color: #fbf8e0;
}


.table tbody tr:hover {
    background-color: #f3da98;
}

.table a {
    color: #007bff;
    text-decoration: none;
}

.table a:hover {
    text-decoration: underline;
}



@media (max-width: 768px) {
    .subscription-info {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}
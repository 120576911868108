.nav-item {
  text-decoration: none;
  color: black;
  font-size:18px;
  padding: 5px 0px 5px 0px;
  width: 120px;
  margin-top: 40px;
  border: 0.5px solid #856900;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
  background-color: #332E1A;
  cursor: pointer;
}

.nav-item>a {
  text-decoration: none;
  color: white;
}

.navbar {
  background-color: #856900 !important
}

.nav-item:hover {
  color: gray;
}

@media screen and (max-width: 600px) {
  .nav-item {
    width: 100%;
    padding: 15px;
    margin: 0;
    border: 0px solid #856900;
    border-radius: 0;
    font-size: 20px
  }
}
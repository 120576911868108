.input {
  border: 1px solid #856900 !important;
  width: 300px !important;
  height: 50px !important;
  background-color: #EFEFEF !important;
  border-radius: 5px !important;
  cursor: pointer;
  border-color: transparent !important
}

.input:hover {
  border: none
}

.input:focus {
  border: 1px solid #856900 !important;
}

.MuiInputBase-root {
  padding-right: 0px !important;
}

.MuiInputBase-input {
  padding: 13.5px 14px
}